import React from 'react';
import "./ReportLayout.css";
import { Outlet } from 'react-router-dom';
import { Content, Header } from 'antd/es/layout/layout';
import { theme } from 'antd';

const ReportLayout: React.FC = () => {
    const { token: { colorBgContainer } } = theme.useToken();

    return (
        <>
            <Header className='layout-header' style={{ background: colorBgContainer }}>Deployment Report</Header>
            <Content
                style={{
                    margin: '16px 16px',
                    overflow: 'hidden',
                    background: colorBgContainer,
                }}
            >
                <Outlet />
            </Content>
        </>
    );
};

export default ReportLayout;