import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEnvironments } from "./use-environments";

export const useComponents = () => {
    const { isSuccess: isEnvLoaded } = useEnvironments();

	return useQuery({
		queryKey: ["components"],
		queryFn: async () => {
			const { data } = await axios.get(process.env.REACT_APP_API_BASE_URL + "/component");

			return data;
        }, enabled: isEnvLoaded
	});
}