import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useComponents } from "./use-components";
import { useApplications } from "./use-applications";
import { useApplicationGroups } from "./use-applications-group";

// enum ReportNames {
// 	"component-environment-version"
// }

export const useReports = (reportName: string) => {
	const { data: components } = useComponents();
	const { data: applications } = useApplications();
	const { data: applicationGroups } = useApplicationGroups();

	// Build maps for O(1) access.
	const componentsMap = new Map<number, any>(components?.map((val:any) => [val.id, val]));
	const applicationsMap = new Map<number, any>(applications?.map((val:any) => [val.id, val]));
	const applicationGroupsMap = new Map<number, any>(applicationGroups?.map((val:any) => [val.id, val]));

	return useQuery({
		queryKey: [`report/${reportName}`],
		queryFn: async () => {
			const { data } = await axios.get(process.env.REACT_APP_API_BASE_URL + `/report/${reportName}`);
			return data;
		},
		select: (data) => {
			return data?.map((val: any) => {
				const environmentVersionMap: any = { }
				val.environmentVersion.forEach((ev: any) => {
					environmentVersionMap[`env${ev.environmentId}`] = {
						...ev
					};
				});

				return {
					...val,
					component: componentsMap.get(val.componentId),
					application: applicationsMap.get(val.applicationId),
					applicationGroup: applicationGroupsMap.get(val.applicationGroupId),
					environmentVersionMap
				}
			}).sort((a: any, b: any) => (a.application_group_id - b.application_group_id) || (a.application_id - b.application_id) || (a.componentId - b.componentId));
		},
		refetchInterval: 30000
	});
}